<template>
  <app-side-menu :min-width="18"
    :fixed-menu="false">
    <aside class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Batch No: {{ entityName }}
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <router-link :to="{ name: routeTypes.OrmBatchingQuotes.name, params: { batchId : $route.params.batchId } }"
            active-class="is-active"
            exact>
            <!-- <span :class="{ 'has-badge-danger has-badge-small has-badge-rounded has-badge-offset2': $v.detailGroup.$error }"
              :data-badge="$v.detailGroup.$error ? '' : null"> -->
            <span>
              <span class="has-badge-child-offset3">Batched Quotes</span>
              <span v-show="count"
                class="has-badge-rounded has-badge-medium has-badge-info"
                :data-badge="count" />
            </span>
          </router-link>
        </li>
        <li>
          <router-link :to="{ name: routeTypes.OrmBatchingAvailableQuotes.name, params: { batchId : $route.params.batchId } }"
            active-class="is-active"
            exact>
            <span>
              Work In Progress
            </span>
          </router-link>
        </li>
        <!-- <li>
          <router-link :to="{ name: routeTypes.OrmBatchingDetail.name, params: { batchId : $route.params.batchId } }"
            active-class="is-active"
            exact>
            <span>
              Draggable
            </span>
          </router-link>
        </li> -->
      </ul>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import OrmBatchingRoutes from './route-types'

export default {
  name: 'OrmBatchingViewSideMenu',
  // inject: ['$vv'],
  components: {
    AppSideMenu
  },
  props: {
    entityName: {
      type: String,
      default: ''
    },
    count: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  computed: {
    $v() {
      return this.$vv || this.$v
    },
    routeTypes() {
      return OrmBatchingRoutes
    }
  },
  mounted() {},
  methods: {}
}
</script>
