<template>
  <div class="tile is-parent is-vertical">
    <article v-if="quotes"
      class="tile is-child box detail-page-tile">
      <div class="field">
        <div class="field-body">
          <div class="field is-narrow">
            <label class="label">Batch No</label>
            <div class="control">
              <vue-numeric class="input has-text-right"
                v-model="innerValue.batchNo"
                :min="0"
                placeholder="Batch No."
                :precision="0" />
            </div>
          </div>
          <div class="field is-narrow">
            <label class="label">Batch Date</label>
            <div class="control">
              <v-date-picker v-model="batchDate"
                :masks="formats"
                :attributes="attrs"
                :locale="$userInfo.locale">
                <template v-slot="{ inputValue, inputEvents }">
                  <div class="field">
                    <div class="control has-icons-left">
                      <input type="text"
                        class="input"
                        placeholder="Batch Date"
                        :value="inputValue"
                        v-on="inputEvents"
                        readonly>
                      <span class="icon is-small is-left">
                        <i class="mdi mdi-calendar mdi-18px" />
                      </span>
                    </div>
                  </div>
                </template>
              </v-date-picker>
            </div>
          </div>
          <div class="field is-narrow">
            <label class="label">Min. Batch Size</label>
            <div class="control">
              <vue-numeric class="input has-text-right"
                v-model="innerValue.minBatchSize"
                :min="0"
                placeholder="Min. Batch Size"
                :precision="0" />
            </div>
          </div>
          <div class="field is-narrow">
            <label class="label">Agreed ARC</label>
            <div class="control">
              <vue-numeric class="input has-text-right"
                v-model="innerValue.agreedArc"
                :min="0"
                symbol="$"
                placeholder="Agreed ARC"
                :precision="2" />
            </div>
          </div>
          <div class="field is-narrow">
            <label class="label">ALR Lower Threshold</label>
            <div class="control">
              <vue-numeric class="input has-text-right"
                v-model="innerValue.alrLowerThreshold"
                :min="0"
                symbol="$"
                placeholder="Agreed ARC"
                :precision="2" />
            </div>
          </div>
          <div class="field is-narrow">
            <label class="label">ALR Upper Threshold</label>
            <div class="control">
              <vue-numeric class="input has-text-right"
                v-model="innerValue.alrUpperThreshold"
                :min="0"
                symbol="$"
                placeholder="Agreed ARC"
                :precision="2" />
            </div>
          </div>
          <div class="field is-narrow">
            <label class="label">Batch Status</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="innerValue.batchStatus">
                  <option v-for="(status, propertyName) in ormBatchStatusType"
                    :value="status"
                    :key="status">
                    {{ propertyName | formatOrmBatchStatus }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="field">
        <div class="field-body">
          <div class="field is-narrow">
            <label class="label">Agreed ARC</label>
            <div class="control">
              <vue-numeric class="input has-text-right"
                v-model="innerValue.agreedArc"
                :min="0"
                symbol="$"
                placeholder="Agreed ARC"
                :precision="2" />
            </div>
          </div>
          <div class="field is-narrow">
            <label class="label">ALR Lower Threshold</label>
            <div class="control">
              <vue-numeric class="input has-text-right"
                v-model="innerValue.alrLowerThreshold"
                :min="0"
                symbol="$"
                placeholder="Agreed ARC"
                :precision="2" />
            </div>
          </div>
          <div class="field is-narrow">
            <label class="label">ALR Upper Threshold</label>
            <div class="control">
              <vue-numeric class="input has-text-right"
                v-model="innerValue.alrUpperThreshold"
                :min="0"
                symbol="$"
                placeholder="Agreed ARC"
                :precision="2" />
            </div>
          </div>
        </div>
      </div> -->
      <div class="is-divider" />
      <orm-batch-statistics :count="quotes ? quotes.length : 0"
        :alr="alr"
        :alr-lower-threshold="innerValue.alrLowerThreshold"
        :alr-upper-threshold="innerValue.alrUpperThreshold"
        :average-labour-units="averageLabourUnits"
        :average-labour-values="averageLabourValues"
        :average-non-labour-values="averageNonLabourValues"
        :average-total-ex-gst="averageTotalExGst"
        :adjusted-total="adjustedTotal" />
    </article>
    <article class="tile is-child box detail-page-tile">
      <div class="columns">
        <div class="column">
          <p class="title">Batched Quotes</p>
        </div>
      </div>
      <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
        :columns="batchQuoteColumns"
        :page-index="filter.pageIndex"
        :page-size="filter.pageSize"
        :total-rows="totalRows"
        :is-loading="isTableLoading"
        :sort-column="filter.sortColumn"
        :sort-order="filter.sortOrder"
        :draggable="false"
        :show-pagination="false"
        @pageChanged="onPageChange"
        @sort="sort">
        <tr v-for="(quote, index) in quotes"
          :key="quote.quoteId"
          :class="{ 'is-new-item': quote.isNew }"
          @click="highlightSelected(index, $event)">
          <td>
            <a href="#"
              @click="viewQuote(quote.quoteId)">{{ quote.quoteNo }}</a>
          </td>
          <td>{{ quote.claimNo }}</td>
          <td>{{ quote.rego }}</td>
          <td>{{ quote.customerName }}</td>
          <td class="has-text-right">{{ quote.excess | formatCurrency($userInfo.locale) }}</td>
          <td class="has-text-right">{{ quote.labourHour | formatNumberWithRounding($userInfo.locale) }}</td>
          <td class="has-text-right">{{ quote.rate | formatCurrency($userInfo.locale) }}</td>
          <td class="has-text-right">{{ quote.labourTotal | formatCurrency($userInfo.locale) }}</td>
          <td class="has-text-right">{{ quote.partTotal | formatCurrency($userInfo.locale) }}</td>
          <td class="has-text-right">{{ quote.subletTotal | formatCurrency($userInfo.locale) }}</td>
          <td class="has-text-right">{{ quote.miscTotal | formatCurrency($userInfo.locale) }}</td>
          <td class="has-text-right">{{ quote.totalExGst | formatCurrency($userInfo.locale) }}</td>
          <td v-show="isInvoiceColumnVisible"
            class="has-text-centered">
            <span class="icon"
              :class="[ quote.invoiced ? 'has-text-success' : 'has-text-danger' ]">
              <i class="mdi mdi-24px"
                :class="[ quote.invoiced ? 'mdi-check' : '' ]" />
            </span>
          </td>
          <td>{{ getPropertyNameByValue(ormQuoteStatusTypes, quote.quoteStatusId) | camelCaseToDescription }}</td>
          <td>{{ quote.qdeStatus }}</td>
          <td class="has-text-right"
            :class="{ 'has-text-danger': delta(quote) < 0, 'has-text-success': delta(quote) >= 0 }">{{ delta(quote) | formatCurrency($userInfo.locale) }}</td>
          <td class="has-vertical-middle has-text-centered is-content-width">
            <span v-show="quote.excess > quote.totalExGst"
              class="icon has-text-danger tooltip is-tooltip-danger is-tooltip-topright"
              data-tooltip="Excess exceeds Total">
              <i class="mdi mdi-alert mdi-24px" />
            </span>
            <button class="button is-danger is-small is-inverted tooltip is-tooltip-topright"
              data-tooltip="Remove from batch"
              @click="removeFromBatch(index, quote)">
              <span class="icon is-medium">
                <i class="mdi mdi-minus mdi-24px" />
              </span>
            </button>
          </td>
        </tr>
        <template slot="empty">
          <section class="section">
            <div class="content has-text-grey has-text-centered">
              <span icon="icon is-large">
                <i class="mdi mdi-48px mdi-emoticon-sad" />
              </span>
              <p>Nothing</p>
            </div>
          </section>
        </template>
      </bulma-table>
    </article>
  </div>
</template>

<script>
import { roundAwayFromZero, roundUp } from '@/components/utils/AccountingFunctions'
import OrmBatchStatistics from './components/OrmBatchStatistics'
import { BatchQuoteColumns } from './columns'
import { DateTimeFiltersMixin, NumberFiltersMixin, TextFiltersMixin } from '@/components/mixins/filters'
import OrmBatchingMixin from './OrmBatchingMixin'
import _cloneDeep from 'lodash/cloneDeep'
// import _orderBy from 'lodash/orderBy'
import BulmaTable from '@/components/BulmaTable'
import { OrmQuoteStatusTypes, OrmBatchStatusType, EventHubTypes } from '@/enums'
import Guid from '@/components/Guid'
import VueNumeric from '@/components/VueNumeric'

export default {
  name: 'OrmBatchingQuotes',
  components: {
    OrmBatchStatistics,
    BulmaTable,
    VueNumeric
  },
  filters: {},
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin, TextFiltersMixin, OrmBatchingMixin],
  props: {
    value: null,
    quotes: null,
    filter: null
  },
  data: () => {
    return {
      innerValue: null,
      batchDate: null,
      isTableLoading: false,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    batchQuoteColumns() {
      let columns = _cloneDeep(BatchQuoteColumns)
      columns[12].isVisible = this.isInvoiceColumnVisible
      return columns
    },
    totalRows() {
      return this.quotes ? this.quotes.length : 0
    },
    isInvoiceColumnVisible() {
      return this.innerValue.batchStatus === OrmBatchStatusType.Completed || this.innerValue.batchStatus === OrmBatchStatusType.Closed
    },
    alr() {
      if (this.quotes.length === 0) {
        return 0
      }
      const batchTotalLabourHour = this.quotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourHour)
      }, 0)
      const batchNonLabourTotal = this.quotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.nonLabourTotal)
      }, 0)
      const batchAgreedTotal = this.quotes.length * this.innerValue.agreedArc
      // console.log(this.quotes.length, batchTotalLabourHour, batchNonLabourTotal, batchAgreedTotal)
      const batchTotalLabourValue = batchAgreedTotal - batchNonLabourTotal
      return batchTotalLabourHour ? roundUp(batchTotalLabourValue / batchTotalLabourHour, 1) : 0
    },
    adjustedTotal() {
      if (this.quotes.length === 0) {
        return 0
      }
      const labourHourTotal = this.quotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourHour)
      }, 0)
      const nonLabourTotal = this.quotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.nonLabourTotal)
      }, 0)
      return roundAwayFromZero(nonLabourTotal + labourHourTotal * this.alr)
    },
    averageLabourUnits() {
      if (this.quotes.length === 0) {
        return 0
      }
      const total = this.quotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourHour)
      }, 0)
      return roundAwayFromZero(total / this.quotes.length)
    },
    averageLabourValues() {
      if (this.quotes.length === 0) {
        return 0
      }
      const total = this.quotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourTotal)
      }, 0)
      return roundAwayFromZero(total / this.quotes.length)
    },
    averageNonLabourValues() {
      if (this.quotes.length === 0) {
        return 0
      }
      const total = this.quotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.nonLabourTotal)
      }, 0)
      return roundAwayFromZero(total / this.quotes.length)
    },
    averageTotalExGst() {
      if (this.quotes.length === 0) {
        return 0
      }
      const total = this.quotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.totalExGst)
      }, 0)
      return roundAwayFromZero(total / this.quotes.length)
    },
    labourUnitTotal() {
      const total = this.quotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourUnit)
      }, 0)
      return total
    },
    ormQuoteStatusTypes() {
      return OrmQuoteStatusTypes
    },
    ormBatchStatusType() {
      return OrmBatchStatusType
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    batchDate: function(newVal, oldVal) {
      if (newVal) {
        this.innerValue.batchDate = newVal.toISOString().split('.')[0] + 'Z'
      } else {
        this.innerValue.batchDate = null
      }
    }
  },
  created() {
    this.innerValue = _cloneDeep(this.value)
    this.batchDate = new Date(`${this.innerValue.batchDate}`)
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = _cloneDeep(this.value)
    })
  },
  mounted() {},
  methods: {
    highlightSelected() {},
    onPageChange() {},
    sort(name, order) {
      this.$emit('sort', name, order)
      // this.filter.sortColumn = name
      // this.filter.sortOrder = order
      // this.quotes = _orderBy(this.quotes, this.filter.sortColumn, this.filter.sortOrder)
    },
    getPropertyNameByValue(object, value) {
      return Object.keys(object)[Object.values(object).findIndex(i => i === value)]
    },
    delta(quote) {
      return quote.totalExGst - this.innerValue.agreedArc
    },
    viewQuote(quoteId) {
      this.$emit('view-quote', quoteId)
    },
    removeFromBatch(index, quote) {
      const existingIndex = this.innerValue.quoteIds
        .map(q => {
          return q.quoteId
        })
        .indexOf(quote.quoteId)
      if (existingIndex >= 0) {
        const quoteId = this.innerValue.quoteIds[existingIndex]
        if (quoteId.isNew) {
          this.innerValue.quoteIds.splice(existingIndex, 1)
        } else {
          quoteId.isDeleted = true
          quoteId.destinationBatchId = this.availableBatch ? this.availableBatch.batchId : Guid.empty()
        }
      } else {
      }
      this.$emit('quote-removed', index, quote)
      // this.entities.splice(index, 1)
    }
  }
}
</script>