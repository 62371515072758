import { TableColumn } from '@/classes'

const Columns = [
  new TableColumn('Batch No.', 'batchNo', true, 'desc', 'left'),
  new TableColumn('Batch Date', 'batchDate', true, 'desc', 'left'),
  new TableColumn('Batch Status', 'batchStatus', true, 'desc', 'left'),
  new TableColumn('Agreed ARC', 'agreedArc', true, 'desc', 'right'),
  new TableColumn('Count', 'count', true, 'desc', 'right'),
  new TableColumn('Avg Labour (Hrs)', 'averageLabourUnit', true, 'desc', 'right'),
  new TableColumn('Avg Labour ($)', 'averageLabourValue', true, 'desc', 'right'),
  new TableColumn('ALR', 'averageLabourRate', true, 'desc', 'right'),
  new TableColumn('ARC', 'averageRepairCost', true, 'desc', 'right'),
  new TableColumn('Adjustment', 'adjustment', false, 'desc', 'right'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const BatchQuoteColumns = [
  new TableColumn('Quote No', 'quoteNo', true, 'desc', 'left'),
  new TableColumn('Claim No', 'claimNo', true, 'desc', 'left'),
  new TableColumn('Rego', 'rego', true, 'desc', 'left'),
  new TableColumn('Name', 'customerName', true, 'desc', 'left'),
  new TableColumn('Excess', 'excess', true, 'desc', 'right'),
  new TableColumn('Labour (Hrs)', 'labourUnit', true, 'desc', 'right'),
  new TableColumn('Rate', 'rate', true, 'desc', 'right'),
  new TableColumn('Labour ($)', 'labourValue', true, 'desc', 'right'),
  new TableColumn('Part', 'partTotal', true, 'desc', 'right'),
  new TableColumn('Sublet', 'subletTotal', true, 'desc', 'right'),
  new TableColumn('Misc', 'miscTotal', true, 'desc', 'right'),
  new TableColumn('Total', 'totalExGst', true, 'desc', 'right'),
  new TableColumn('Inv\'d', 'invoiced', true, 'desc', 'centered'),
  // new TableColumn('Inv No', 'invoiceNo', true, 'desc', 'left'),
  new TableColumn('Status', 'quoteStatusId', false, 'desc', 'left'),
  new TableColumn('QDE Status', 'qdeStatus', false, 'desc', 'left'),
  new TableColumn('Diff', 'delta', true, 'desc', 'right'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

const AvailableQuoteColumns = [
  new TableColumn('Quote No.', 'quoteNo', true, 'desc', 'left'),
  new TableColumn('Claim No', 'claimNo', true, 'desc', 'left'),
  new TableColumn('Rego', 'rego', true, 'desc', 'left'),
  new TableColumn('Name', 'customerName', true, 'desc', 'left'),
  new TableColumn('Excess', 'excess', true, 'desc', 'right'),
  new TableColumn('Labour (Hrs)', 'labourUnit', true, 'desc', 'right'),
  new TableColumn('Rate', 'rate', true, 'desc', 'right'),
  new TableColumn('Labour ($)', 'labourValue', true, 'desc', 'right'),
  new TableColumn('Part', 'partTotal', true, 'desc', 'right'),
  new TableColumn('Sublet', 'subletTotal', true, 'desc', 'right'),
  new TableColumn('Misc', 'miscTotal', true, 'desc', 'right'),
  new TableColumn('Total', 'totalExGst', true, 'desc', 'right'),
  // new TableColumn('Diff', 'delta', true, 'desc', 'right'),
  new TableColumn('', 'action', false, 'desc', 'centered')
]

export { Columns, BatchQuoteColumns, AvailableQuoteColumns }
