<template>
  <div v-if="$userInfo">
    <app-detail-header :show-toggle="true"
      :show-delete="false"
      :show-save="false"
      :show-cancel="false"
      :show-new="true"
      :show-print="true"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <orm-batching-list-side-menu v-model="filter"
          @filter="filterList"
          @reset="resetList" />
      </div>
      <div class="column">
        <div class="tile is-parent">
          <article class="tile is-child box detail-page-tile">
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="columns"
              :page-index="filter.pageIndex"
              :page-size="filter.pageSize"
              :total-rows="totalRows"
              :is-loading="isTableLoading"
              :sort-column="filter.sortColumn"
              :sort-order="filter.sortOrder"
              @pageChanged="onPageChange"
              @sort="sort">
              <tr v-for="(batch, index) in entities"
                :key="batch.batchNo"
                :class="{ 'is-selected' : selectedRow === index }"
                @click="highlightSelected(index, $event)">
                <td>
                  <router-link :to="{ path: `${batch.batchId}` }"
                    append>
                    {{ batch.batchNo }}
                  </router-link>
                </td>
                <td>{{ $filters.formatDateLocale(batch.batchDate, $userInfo.locale) }}</td>
                <td>{{ batch.batchStatus | formatBatchStatus }}</td>
                <td class="has-text-right">{{ batch.agreedArc | formatCurrency($userInfo.locale) }}</td>
                <td class="has-text-right"
                  :class="{ 'has-text-danger' : batch.count < batch.minBatchSize }">{{ batch.count }}/{{ batch.minBatchSize }}</td>
                <td class="has-text-right">{{ batch.averageLabourUnit | formatNumberWithRounding($userInfo.locale) }}</td>
                <td class="has-text-right">{{ batch.averageLabourValue | formatCurrency($userInfo.locale) }}</td>
                <td class="has-text-right">{{ batch.averageLabourRate | formatNumberWithRounding($userInfo.locale) }}</td>
                <td class="has-text-right">{{ batch.averageRepairCost | formatCurrency($userInfo.locale) }}</td>
                <td class="has-text-right"
                  :class="{ 'has-text-danger': delta(batch) < 0, 'has-text-success': delta(batch) >= 0 }">{{ delta(batch) | formatCurrency($userInfo.locale) }}</td>
                <td class="has-text-centered">
                  <router-link :to="{ path: `${batch.batchId}` }"
                    append>
                    <a class="button is-primary is-small is-inverted tooltip"
                      data-tooltip="Click to edit">
                      <span class="icon is-medium">
                        <i class="mdi mdi-lead-pencil mdi-24px" />
                      </span>
                    </a>
                  </router-link>
                </td>
              </tr>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered">
                    <span icon="icon is-large">
                      <i class="mdi mdi-48px mdi-emoticon-sad" />
                    </span>
                    <p>Nothing</p>
                  </div>
                </section>
              </template>
            </bulma-table>
          </article>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import OrmBatchingListSideMenu from './OrmBatchingListSideMenu'
import BulmaTable from '@/components/BulmaTable'
import { Columns } from './columns'
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import { AppHeaderButtonTypes } from '@/enums'
import OrmBatchingService from './OrmBatchingService'
import { OrmBatchStatusType } from '@/enums'
import StoreMixin from './storeMixin'

export default {
  name: 'OrmBatchingListView',
  components: {
    AppDetailHeader,
    OrmBatchingListSideMenu,
    BulmaTable
  },
  filters: {
    formatBatchStatus(value) {
      const index = Object.values(OrmBatchStatusType).indexOf(value)
      const status = Object.keys(OrmBatchStatusType)[index]
      return status.replace(/([a-z])([A-Z])/g, '$1 $2')
          .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
          .replace(/^./, function(str) {
            return str.toUpperCase()
          })
    }
  },
  mixins: [StoreMixin, DateTimeFiltersMixin, NumberFiltersMixin],
  props: {},
  data: () => {
    return {
      isTableLoading: false,
      totalRows: 0,
      selectedRow: null,
      filter: {
        batchNo: null,
        batchDate: '',
        batchStatus: 0,
        sortColumn: Columns[0].name,
        sortOrder: Columns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 18
      },
      entities: null
    }
  },
  computed: {
    columns() {
      return Columns
    },
    filterKey() {
      if (this.$userInfo) {
        return `${this.$userInfo.sessionId}|${this.$route.meta.fkey}`
      } else {
        return ''
      }
    }
  },
  watch: {},
  created() {
    this.getEntitySummaries()
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          this.addNew()
          break
        case AppHeaderButtonTypes.Print:
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          break
        case AppHeaderButtonTypes.Cancel:
          break
      }
    },
    highlightSelected(index, event) {},
    onPageChange(pageIndex) {
      this.filter.pageIndex = pageIndex
      this.persistFilter()
      this.getEntitySummaries()
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.persistFilter()
      this.getEntitySummaries()
    },
    filterList() {
      this.filter.pageIndex = 1
      this.persistFilter()
      this.getEntitySummaries()
    },
    resetList() {
      this.filter.batchNo = null
      this.filter.batchStatus = 0
      this.filter.batchDate = ''
      this.filter.messageTypeId = 0
      this.filter.sortColumn = Columns[0].name
      this.filter.sortOrder = Columns[0].defaultOrder
      this.filter.pageIndex = 1
      this.filter.pageSize = 18
      this.persistFilter()
      this.getEntitySummaries()
    },
    persistFilter() {
      sessionStorage.setItem(this.filterKey, JSON.stringify(this.filter))
    },
    retrieveFilter() {
      const filter = JSON.parse(sessionStorage.getItem(this.filterKey))
      if (filter) {
        this.filter = filter
      }
    },
    async getEntitySummaries() {
      this.isTableLoading = true
      this.retrieveFilter()
      this.entities = await OrmBatchingService.getEntitySummaries(this.filter)
      this.totalRows = this.entities.length > 0 ? this.entities[0].totalRows : 0
      this.isTableLoading = false
    },
    delta(batch) {
      return batch.averageRepairCost - batch.agreedArc
    },
    async addNew() {
      this.addStoreItem()
    }
  }
}
</script>