<template>
  <div class="tile is-parent is-vertical">
    <article v-if="quotes"
      class="tile is-child box detail-page-tile">
      <orm-batch-statistics :count="quotes ? quotes.length : 0"
        :alr="alr"
        :alr-lower-threshold="innerValue.alrLowerThreshold"
        :alr-upper-threshold="innerValue.alrUpperThreshold"
        :average-labour-units="averageLabourUnits"
        :average-labour-values="averageLabourValues"
        :average-non-labour-values="averageNonLabourValues"
        :average-total-ex-gst="averageTotalExGst"
        :adjusted-total="adjustedTotal" />
    </article>
    <article class="tile is-child box detail-page-tile">
      <div class="columns">
        <div class="column">
          <p class="title">In Scope</p>
          <div class="field is-narrow has-addons">
            <div class="control has-icons-left">
              <input class="input"
                v-model="availableQuoteQuery"
                type="text"
                placeholder="Start typing to search">
              <span class="icon is-left">
                <i class="mdi mdi-magnify mdi-24px" />
              </span>
            </div>
            <div class="control">
              <a class="button is-primary is-outlined tooltip"
                data-tooltip="Reset search"
                @click="availableQuoteQuery = ''">
                <span class="icon">
                  <i class="mdi mdi-close mdi-24px" />
                </span>
              </a>
            </div>
          </div>
        </div>
        <div class="column is-narrow">
          <div class="field-body">
            <div class="field-label is-normal">
              <label class="label">Source</label>
            </div>
            <div class="field">
              <div class="control">
                <div class="select">
                  <select v-model="selectedAvailableBatchId">
                    <option :value="guid.empty()">Not Batched</option>
                    <option v-for="(activeBatchId) in activeBatchIds"
                      :value="activeBatchId.key"
                      :key="activeBatchId.value">
                      {{ `Batch No. ${activeBatchId.value}` }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <orm-batch-statistics v-if="availableBatch"
        :count="availableQuotes ? availableQuotes.length : 0"
        :alr="availableAlr"
        :alr-lower-threshold="availableBatch.alrLowerThreshold"
        :alr-upper-threshold="availableBatch.alrUpperThreshold"
        :average-labour-units="availableAverageLabourUnits"
        :average-labour-values="availableAverageLabourValues"
        :average-non-labour-values="availableAverageNonLabourValues"
        :average-total-ex-gst="availableAverageTotalExGst"
        :adjusted-total="availableAdjustedTotal" />
      <div v-if="availableBatch"
        class="is-divider" />
      <div class="field">
        <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
          :columns="availableQuoteColumns"
          :page-index="availableQuoteFilter.pageIndex"
          :page-size="availableQuoteFilter.pageSize"
          :total-rows="availableQuoteTotalRows"
          :is-loading="isAvailableQuoteTableLoading"
          :sort-column="availableQuoteFilter.sortColumn"
          :sort-order="availableQuoteFilter.sortOrder"
          :draggable="false"
          :show-pagination="false"
          @pageChanged="onAvailableQuotePageChange"
          @sort="onAvailableQuoteSort">
          <tr v-for="(quote, index) in filteredAvailableQuotes"
            :key="quote.quoteId"
            @click="highlightSelectedAvailableQuote(index, $event)">
            <td>
              <a href="#"
                @click="viewQuote(quote.quoteId)">{{ quote.quoteNo }}</a>
            </td>
            <td>{{ quote.claimNo }}</td>
            <td>{{ quote.rego }}</td>
            <td>{{ quote.customerName }}</td>
            <td class="has-text-right">{{ quote.excess | formatCurrency($userInfo.locale) }}</td>
            <td class="has-text-right">{{ quote.labourHour | formatNumber($userInfo.locale) }}</td>
            <td class="has-text-right">{{ quote.rate | formatCurrency($userInfo.locale) }}</td>
            <td class="has-text-right">{{ quote.labourTotal | formatCurrency($userInfo.locale) }}</td>
            <td class="has-text-right">{{ quote.partTotal | formatCurrency($userInfo.locale) }}</td>
            <td class="has-text-right">{{ quote.subletTotal | formatCurrency($userInfo.locale) }}</td>
            <td class="has-text-right">{{ quote.miscTotal | formatCurrency($userInfo.locale) }}</td>
            <td class="has-text-right">{{ quote.totalExGst | formatCurrency($userInfo.locale) }}</td>
            <!-- <td class="has-text-right">{{ quote.delta | formatNumber($userInfo.locale) }}</td> -->
            <td class="has-vertical-middle has-text-centered is-content-width">
              <button class="button is-success is-small is-inverted tooltip is-tooltip-topright"
                @click="addToBatch(index, quote)"
                data-tooltip="Add to batch">
                <span class="icon is-medium">
                  <i class="mdi mdi-plus mdi-24px" />
                </span>
              </button>
            </td>
          </tr>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <span icon="icon is-large">
                  <i class="mdi mdi-48px mdi-emoticon-sad" />
                </span>
                <p>Nothing</p>
              </div>
            </section>
          </template>
        </bulma-table>
      </div>
    </article>
    <article class="tile is-child box detail-page-tile">
      <p class="title">Out Of Scope</p>
      <div class="field">
        <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
          :columns="availableQuoteColumns"
          :page-index="outOfScopeQuoteFilter.pageIndex"
          :page-size="outOfScopeQuoteFilter.pageSize"
          :total-rows="outOfScopeQuoteTotalRows"
          :is-loading="isOutOfScopQuoteTableLoading"
          :sort-column="outOfScopeQuoteFilter.sortColumn"
          :sort-order="outOfScopeQuoteFilter.sortOrder"
          :draggable="false"
          :show-pagination="false"
          @pageChanged="onAvailableQuotePageChange"
          @sort="onAvailableQuoteSort">
          <tr v-for="(quote, index) in outOfScopeQuotes"
            :key="quote.quoteId"
            @click="highlightSelectedAvailableQuote(index, $event)">
            <td>
              <a href="#"
                @click="viewQuote(quote.quoteId)">{{ quote.quoteNo }}</a>
            </td>
            <td>{{ quote.claimNo }}</td>
            <td>{{ quote.rego }}</td>
            <td>{{ quote.customerName }}</td>
            <td class="has-text-right">{{ quote.excess | formatCurrency($userInfo.locale) }}</td>
            <td class="has-text-right">{{ quote.labourHour | formatNumber($userInfo.locale) }}</td>
            <td class="has-text-right">{{ quote.rate | formatCurrency($userInfo.locale) }}</td>
            <td class="has-text-right">{{ quote.labourTotal | formatCurrency($userInfo.locale) }}</td>
            <td class="has-text-right">{{ quote.partTotal | formatCurrency($userInfo.locale) }}</td>
            <td class="has-text-right">{{ quote.subletTotal | formatCurrency($userInfo.locale) }}</td>
            <td class="has-text-right">{{ quote.miscTotal | formatCurrency($userInfo.locale) }}</td>
            <td class="has-text-right">{{ quote.totalExGst | formatCurrency($userInfo.locale) }}</td>
            <!-- <td class="has-text-right">{{ quote.delta | formatNumber($userInfo.locale) }}</td> -->
            <td class="has-vertical-middle has-text-centered is-content-width">
              <!-- <button class="button is-success is-small is-inverted tooltip is-tooltip-topright"
                @click="addToBatch(index, quote)"
                data-tooltip="Add to batch">
                <span class="icon is-medium">
                  <i class="mdi mdi-plus mdi-24px" />
                </span>
              </button> -->
            </td>
          </tr>
          <template slot="empty">
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <span icon="icon is-large">
                  <i class="mdi mdi-48px mdi-emoticon-sad" />
                </span>
                <p>Nothing</p>
              </div>
            </section>
          </template>
        </bulma-table>
      </div>
    </article>
  </div>
</template>

<script>
import { AvailableQuoteColumns } from './columns'
import OrmBatchingService from './OrmBatchingService'
import BulmaTable from '@/components/BulmaTable'
import { OrmQuoteStatusTypes, OrmBatchStatusType, EventHubTypes } from '@/enums'
import Guid from '@/components/Guid'
import _orderBy from 'lodash/orderBy'
import { DateTimeFiltersMixin, NumberFiltersMixin, TextFiltersMixin } from '@/components/mixins/filters'
import OrmBatchStatistics from './components/OrmBatchStatistics'
import { roundAwayFromZero, roundUp } from '@/components/utils/AccountingFunctions'
import _cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'OrmBatchingAvailableQuotes',
  components: {
    BulmaTable,
    OrmBatchStatistics
  },
  filters: {},
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin, TextFiltersMixin],
  props: {
    value: null,
    quotes: null,
    removedQuotes: null
  },
  data: () => {
    return {
      innerValue: null,
      availableQuotes: null,
      availableQuoteQuery: '',
      availableQuoteTotalRows: 0,
      isAvailableQuoteTableLoading: false,
      isAvailableQuoteRowDraggable: true,
      availableQuoteDrag: false,
      availableQuoteFilter: {
        sortColumn: AvailableQuoteColumns[0].name,
        sortOrder: AvailableQuoteColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 100
      },
      activeBatchIds: null,
      selectedAvailableBatchId: Guid.empty(),
      availableBatch: null,
      isOutOfScopQuoteTableLoading: false,
      outOfScopeQuoteFilter: {
        sortColumn: AvailableQuoteColumns[0].name,
        sortOrder: AvailableQuoteColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 100
      }
    }
  },
  computed: {
    guid() {
      return Guid
    },
    availableQuoteColumns() {
      return AvailableQuoteColumns
    },
    alr() {
      if (this.quotes.length === 0) {
        return 0
      }
      const batchTotalLabourHour = this.quotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourHour)
      }, 0)
      const batchNonLabourTotal = this.quotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.nonLabourTotal)
      }, 0)
      const batchAgreedTotal = this.quotes.length * this.innerValue.agreedArc
      // console.log(this.quotes.length, batchTotalLabourHour, batchNonLabourTotal, batchAgreedTotal)
      const batchTotalLabourValue = batchAgreedTotal - batchNonLabourTotal
      return batchTotalLabourHour ? roundUp(batchTotalLabourValue / batchTotalLabourHour, 1) : 0
    },
    adjustedTotal() {
      if (this.quotes.length === 0) {
        return 0
      }
      const labourHourTotal = this.quotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourHour)
      }, 0)
      const nonLabourTotal = this.quotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.nonLabourTotal)
      }, 0)
      return roundAwayFromZero(nonLabourTotal + labourHourTotal * this.alr)
    },
    averageLabourUnits() {
      if (this.quotes.length === 0) {
        return 0
      }
      const total = this.quotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourHour)
      }, 0)
      return roundAwayFromZero(total / this.quotes.length)
    },
    averageLabourValues() {
      if (this.quotes.length === 0) {
        return 0
      }
      const total = this.quotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourTotal)
      }, 0)
      return roundAwayFromZero(total / this.quotes.length)
    },
    averageNonLabourValues() {
      if (this.quotes.length === 0) {
        return 0
      }
      const total = this.quotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.nonLabourTotal)
      }, 0)
      return roundAwayFromZero(total / this.quotes.length)
    },
    averageTotalExGst() {
      if (this.quotes.length === 0) {
        return 0
      }
      const total = this.quotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.totalExGst)
      }, 0)
      return roundAwayFromZero(total / this.quotes.length)
    },
    labourUnitTotal() {
      const total = this.quotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourUnit)
      }, 0)
      return total
    },
    availableAlr() {
      if (!this.availableQuotes || this.availableQuotes.length === 0) {
        return 0
      }
      const batchTotalLabourHour = this.availableQuotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourHour)
      }, 0)
      const batchNonLabourTotal = this.availableQuotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.nonLabourTotal)
      }, 0)
      const batchAgreedTotal = this.availableQuotes.length * this.availableBatch.agreedArc
      const batchTotalLabourValue = batchAgreedTotal - batchNonLabourTotal
      return batchTotalLabourHour ? roundUp(batchTotalLabourValue / batchTotalLabourHour, 1) : 0
    },
    availableAdjustedTotal() {
      if (!this.availableQuotes || this.availableQuotes.length === 0) {
        return 0
      }
      const labourHourTotal = this.availableQuotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourHour)
      }, 0)
      const nonLabourTotal = this.availableQuotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.nonLabourTotal)
      }, 0)
      return roundAwayFromZero(nonLabourTotal + labourHourTotal * this.availableAlr)
    },
    availableAverageLabourUnits() {
      if (!this.availableQuotes || this.availableQuotes.length === 0) {
        return 0
      }
      const total = this.availableQuotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourHour)
      }, 0)
      return roundAwayFromZero(total / this.availableQuotes.length)
    },
    availableAverageLabourValues() {
      if (!this.availableQuotes || this.availableQuotes.length === 0) {
        return 0
      }
      const total = this.availableQuotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourTotal)
      }, 0)
      return roundAwayFromZero(total / this.availableQuotes.length)
    },
    availableAverageNonLabourValues() {
      if (!this.availableQuotes || this.availableQuotes.length === 0) {
        return 0
      }
      const total = this.availableQuotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.nonLabourTotal)
      }, 0)
      return roundAwayFromZero(total / this.availableQuotes.length)
    },
    availableAverageTotalExGst() {
      if (!this.availableQuotes || this.availableQuotes.length === 0) {
        return 0
      }
      const total = this.availableQuotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.totalExGst)
      }, 0)
      return roundAwayFromZero(total / this.availableQuotes.length)
    },
    availableLabourUnitTotal() {
      if (!this.availableQuotes) {
        return 0
      }
      const total = this.availableQuotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourUnit)
      }, 0)
      return total
    },
    filteredAvailableQuotes() {
      if (!this.availableQuotes) {
        return null
      }
      this.toggleTableLoading(true)
      const query = this.availableQuoteQuery.toLowerCase()
      let results = this.availableQuotes
      if (this.availableQuoteQuery) {
        results = this.availableQuotes.filter(q => q.quoteNo.toLowerCase().includes(query) || q.rego.toLowerCase().includes(query))
      }
      // if (this.totalLowerLimit) {
      //   results = results.filter(q => q.totalExGst >= this.totalLowerLimit)
      // }
      // if (this.totalUpperLimit) {
      //   results = results.filter(q => q.totalExGst <= this.totalUpperLimit)
      // }
      this.toggleTableLoading(false)
      return results.filter(q => q.quoteInScope)
    },
    outOfScopeQuoteTotalRows() {
      return this.outOfScopeQuotes ? this.outOfScopeQuotes.length : 0
    },
    outOfScopeQuotes() {
      if (!this.availableQuotes) {
        return null
      }
      return this.availableQuotes.filter(q => !q.quoteInScope)
    },
    ormQuoteStatusTypes() {
      return OrmQuoteStatusTypes
    },
    ormBatchStatusType() {
      return OrmBatchStatusType
    }
  },
  watch: {
    innerValue: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    selectedAvailableBatchId(newVal, oldVal) {
      this.getBatchQuoteAvailableSummaries()
      if (newVal !== Guid.empty()) {
        console.log(newVal)
        this.getAvailableBatch(newVal)
      } else {
        this.availableBatch = null
        this.updateDestinationBatch(Guid.empty())
      }
    }
  },
  created() {
    this.innerValue = _cloneDeep(this.value)
    this.getActiveBatchIds()
    this.getBatchQuoteAvailableSummaries()
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.innerValue = _cloneDeep(this.value)
    })
  },
  mounted() {},
  methods: {
    highlightSelectedAvailableQuote() {},
    onAvailableQuotePageChange() {},
    onAvailableQuoteSort(name, order) {
      this.availableQuoteFilter.sortColumn = name
      this.availableQuoteFilter.sortOrder = order
      this.availableQuotes = _orderBy(this.availableQuotes, this.availableQuoteFilter.sortColumn, this.availableQuoteFilter.sortOrder)
    },
    async getBatchQuoteAvailableSummaries() {
      this.isAvailableQuoteTableLoading = true
      this.availableQuotes = null
      const results = await OrmBatchingService.getBatchQuoteAvailableSummaries(this.selectedAvailableBatchId)
      const newQuoteIds = this.innerValue.quoteIds.filter(q => q.isNew)
      newQuoteIds.forEach(q => {
        const index = results.findIndex(r => {
          return r.quoteId === q.quoteId
        })
        if (index !== -1) {
          results.splice(index, 1)
        }
      })
      this.availableQuotes = _orderBy([...results, ...this.removedQuotes], this.availableQuoteFilter.sortColumn, this.availableQuoteFilter.sortOrder)
      this.availableQuoteTotalRows = this.availableQuotes.length
      this.isAvailableQuoteTableLoading = false
    },
    async getActiveBatchIds() {
      this.activeBatchIds = await OrmBatchingService.getActiveBatchIds(this.innerValue.batchId)
    },
    async getAvailableBatch(batchId) {
      this.availableBatch = await OrmBatchingService.getEntity(batchId)
      this.updateDestinationBatch(this.availableBatch.batchId)
    },
    updateDestinationBatch(id) {
      const vm = this
      this.removedQuotes.forEach(q => {
        const quoteId = vm.innerValue.quoteIds.find(q => q.quoteId === q.quoteId)
        quoteId.destinationBatchId = id
      })
    },
    viewQuote(quoteId) {
      this.$emit('view-quote', quoteId)
    },
    addToBatch(index, quote) {
      const existingIndex = this.innerValue.quoteIds
        .map(q => {
          return q.quoteId
        })
        .indexOf(quote.quoteId)
      // console.log(existingIndex)
      if (existingIndex === -1) {
        this.innerValue.quoteIds.splice(this.innerValue.quoteIds.length, 1, {
          isDeleted: false,
          isDirty: false,
          isNew: true,
          quoteId: quote.quoteId,
          sourceBatchId: this.availableBatch ? this.availableBatch.batchId : Guid.empty()
        })
        quote.isNew = true
        if (this.availableBatch) {
          quote.sourceBatchId = this.availableBatch.batchId
        } else {
          quote.sourceBatchId = Guid.empty()
        }
      } else {
        const quoteId = this.innerValue.quoteIds[existingIndex]
        quoteId.destinationBatchId = Guid.empty()
        quoteId.isDeleted = false
      }
      const availableQuoteIndex = this.availableQuotes
        .map(q => {
          return q.quoteId
        })
        .indexOf(quote.quoteId)
      this.availableQuotes.splice(availableQuoteIndex, 1)
      this.availableQuoteTotalRows = this.availableQuotes.length
      this.$emit('quote-added', quote)
    },
    toggleTableLoading(value) {
      this.isAvailableQuoteTableLoading = value
    }
  }
}
</script>