<template>
  <div class="tile">
    <!-- <div class="columns is-gapless"> -->
    <!-- <div class="column"> -->
    <div class="tile is-parent is-vertical">
      <article class="tile is-child box detail-page-tile"
        style="max-height: 250px">
        <p class="title">Summary</p>
        <div class="field">
          <div class="field-body">
            <div class="field is-narrow">
              <label class="label">Batch No</label>
              <div class="control">
                <vue-numeric class="input has-text-right"
                  v-model="innerValue.batchNo"
                  :min="0"
                  placeholder="Batch No."
                  :precision="0" />
              </div>
            </div>
            <div class="field is-narrow">
              <label class="label">Batch Date</label>
              <div class="control">
                <v-date-picker v-model="batchDate"
                  :masks="formats"
                  :attributes="attrs"
                  :locale="$userInfo.locale">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="field">
                      <div class="control has-icons-left">
                        <input type="text"
                          class="input"
                          placeholder="Batch Date"
                          :value="inputValue"
                          v-on="inputEvents"
                          readonly>
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
            <div class="field is-narrow">
              <label class="label">Min. Batch Size</label>
              <div class="control">
                <vue-numeric class="input has-text-right"
                  v-model="innerValue.minBatchSize"
                  :min="0"
                  placeholder="Min. Batch Size"
                  :precision="0" />
              </div>
            </div>
            <div class="field is-narrow">
              <label class="label">Batch Status</label>
              <div class="control">
                <div class="select is-fullwidth">
                  <select v-model="innerValue.batchStatus">
                    <option v-for="(status, propertyName) in ormBatchStatusType"
                      :value="status"
                      :key="status">
                      {{ propertyName | formatOrmBatchStatus }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <div class="field-body">
            <div class="field is-narrow">
              <label class="label">Agreed ARC</label>
              <div class="control">
                <vue-numeric class="input has-text-right"
                  v-model="innerValue.agreedArc"
                  :min="0"
                  symbol="$"
                  placeholder="Agreed ARC"
                  :precision="2" />
              </div>
            </div>
            <div class="field is-narrow">
              <label class="label">ALR Lower Threshold</label>
              <div class="control">
                <vue-numeric class="input has-text-right"
                  v-model="innerValue.alrLowerThreshold"
                  :min="0"
                  symbol="$"
                  placeholder="Agreed ARC"
                  :precision="2" />
              </div>
            </div>
            <div class="field is-narrow">
              <label class="label">ALR Upper Threshold</label>
              <div class="control">
                <vue-numeric class="input has-text-right"
                  v-model="innerValue.alrUpperThreshold"
                  :min="0"
                  symbol="$"
                  placeholder="Agreed ARC"
                  :precision="2" />
              </div>
            </div>
          </div>
        </div>
        <div class="is-divider" />
        <orm-batch-statistics v-if="entities"
          :count="entities ? entities.length : 0"
          :alr="alr"
          :alr-lower-threshold="innerValue.alrLowerThreshold"
          :alr-upper-threshold="innerValue.alrUpperThreshold"
          :average-labour-units="averageLabourUnits"
          :average-labour-values="averageLabourValues"
          :average-non-labour-values="averageNonLabourValues"
          :average-total-ex-gst="averageTotalExGst"
          :adjusted-total="adjustedTotal" />
      </article>
      <article class="tile is-child box detail-page-tile">
        <div class="columns">
          <div class="column">
            <p class="title">Batched Quotes</p>
          </div>
        </div>
        <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
          :columns="batchQuoteColumns"
          :page-index="filter.pageIndex"
          :page-size="filter.pageSize"
          :total-rows="totalRows"
          :is-loading="isTableLoading"
          :sort-column="filter.sortColumn"
          :sort-order="filter.sortOrder"
          :draggable="true"
          :draggable-empty="true"
          :show-pagination="false"
          @pageChanged="onPageChange"
          @sort="sort">
          <draggable :list="entities"
            group="quote"
            v-bind="{'disabled': !isRowDraggable, chosen: 'chosen', ghostClass: 'draggable-ghost', dragClass: 'draggable-drag', animation: animationDuration}"
            :tag="'tbody'"
            @change="itemMoved"
            @end="endMove">
            <tr v-for="(quote, index) in entities"
              :key="quote.quoteId"
              :class="{ 'is-new-item': quote.isNew }"
              @click="highlightSelected(index, $event)">
              <td>
                <a href="#"
                  @click="viewQuote(quote.quoteId)">{{ quote.quoteNo }}</a>
              </td>
              <td v-show="!isAddQuoteActive">{{ quote.claimNo }}</td>
              <td>{{ quote.rego }}</td>
              <td v-show="!isAddQuoteActive">{{ quote.customerName }}</td>
              <td v-show="!isAddQuoteActive"
                class="has-text-right">{{ quote.excess | formatNumber($userInfo.locale) }}</td>
              <td class="has-text-right">{{ quote.labourHour | formatNumber($userInfo.locale) }}</td>
              <td class="has-text-right">{{ quote.rate | formatNumber($userInfo.locale) }}</td>
              <td class="has-text-right">{{ quote.labourTotal | formatNumber($userInfo.locale) }}</td>
              <td class="has-text-right">{{ quote.partTotal | formatNumber($userInfo.locale) }}</td>
              <td class="has-text-right">{{ quote.subletTotal | formatNumber($userInfo.locale) }}</td>
              <td class="has-text-right">{{ quote.miscTotal | formatNumber($userInfo.locale) }}</td>
              <td class="has-text-right">{{ quote.totalExGst | formatNumber($userInfo.locale) }}</td>
              <td v-show="isInvoiceColumnVisible"
                class="has-text-centered">
                <span class="icon"
                  :class="[ quote.invoiced ? 'has-text-success' : 'has-text-danger' ]">
                  <i class="mdi mdi-24px"
                    :class="[ quote.invoiced ? 'mdi-check' : '' ]" />
                </span>
              </td>
              <td v-show="!isAddQuoteActive">{{ getPropertyNameByValue(ormQuoteStatusTypes, quote.quoteStatusId) | camelCaseToDescription }}</td>
              <td v-show="!isAddQuoteActive">{{ quote.qdeStatus }}</td>
              <td class="has-text-right"
                :class="{ 'has-text-danger': delta(quote) < 0, 'has-text-success': delta(quote) >= 0 }">{{ delta(quote) | formatNumber($userInfo.locale) }}</td>
              <td class="has-vertical-middle has-text-centered is-content-width">
                <span v-show="quote.excess > quote.totalExGst"
                  class="icon has-text-danger tooltip is-tooltip-danger is-tooltip-topright"
                  data-tooltip="Excess exceeds Total">
                  <i class="mdi mdi-alert mdi-24px" />
                </span>
                <button v-show="isAddQuoteActive"
                  class="button is-danger is-small is-inverted tooltip is-tooltip-topright"
                  data-tooltip="Remove from batch"
                  @click="removeFromBatch(index, quote)">
                  <span class="icon is-medium">
                    <i class="mdi mdi-minus mdi-24px" />
                  </span>
                </button>
              </td>
            </tr>
          </draggable>
          <template slot="empty">
            <draggable :list="entities"
              group="quote"
              v-bind="{'disabled': !isRowDraggable, chosen: 'chosen', ghostClass: 'draggable-ghost', dragClass: 'draggable-drag', animation: animationDuration}"
              :tag="'tbody'"
              @change="itemMoved"
              @end="endMove">
              <tr>
                <td :colspan="batchQuoteColumns.length">
                  <section class="section">
                    <div class="content has-text-grey has-text-centered">
                      <span icon="icon is-large">
                        <i class="mdi mdi-48px mdi-emoticon-sad" />
                      </span>
                      <p>Nothing</p>
                    </div>
                  </section>
                </td>
              </tr>
            </draggable>
          </template>
        </bulma-table>
      </article>
    </div>
    <!-- </div> -->
    <transition name="custom-classes-transition"
      enter-active-class="animated slideInRight"
      leave-active-class="animated slideOutRight">
      <!-- <div class="column is-narrow"
        style="max-width:50vw;"> -->
      <div v-show="isAddQuoteActive"
        class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <div class="columns">
            <div class="column">
              <p class="title">Available Quotes</p>
              <div class="field-body">
                <div class="field">
                  <label class="label">Search</label>
                  <div class="control">
                    <input class="input"
                      v-model="availableQuoteQuery"
                      type="text"
                      placeholder="Start typing to search">
                  </div>
                  <!-- <div class="control">
                    <a class="button is-primary is-outlined tooltip"
                      data-tooltip="Reset search"
                      @click="availableQuoteQuery = ''"
                      :disabled="!availableQuoteQuery">
                      <span class="icon">
                        <i class="mdi mdi-close mdi-24px" />
                      </span>
                    </a>
                  </div> -->
                </div>
                <div class="field">
                  <label class="label">Total Lower Limit</label>
                  <div class="control">
                    <vue-numeric class="input has-text-right"
                      v-model="totalLowerLimit"
                      :min="null"
                      placeholder="Total Lower Limit"
                      :precision="0" />
                  </div>
                </div>
                <div class="field">
                  <label class="label">Total Upper Limit</label>
                  <vue-numeric class="input has-text-right"
                    v-model="totalUpperLimit"
                    :min="0"
                    placeholder="Total Upper Limit"
                    :precision="0" />
                </div>
              </div>
            </div>
            <div class="column is-narrow">
              <div class="field-body">
                <div class="field-label is-normal">
                  <label class="label">Source</label>
                </div>
                <div class="field">
                  <div class="control">
                    <div class="select">
                      <select v-model="selectedAvailableBatchId">
                        <option :value="guid.empty()">Not Batched</option>
                        <option v-for="(activeBatchId) in activeBatchIds"
                          :value="activeBatchId.key"
                          :key="activeBatchId.value">
                          {{ `Batch No. ${activeBatchId.value}` }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="field">
                  <div class="control is-pulled-right">
                    <button class="button is-primary"
                      @click="closeAvailableQuotes()">
                      <span class="icon">
                        <i class="mdi mdi-close" />
                      </span>
                      <span>Close</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selectedAvailableBatchId !== guid.empty()"
            class="is-divider" />
          <div v-if="selectedAvailableBatchId !== guid.empty() && availableBatch"
            class="field">
            <orm-batch-statistics v-if="availableQuotes"
              :count="availableQuotes ? availableQuotes.length : 0"
              :alr="availableAlr"
              :alr-lower-threshold="availableBatch.alrLowerThreshold"
              :alr-upper-threshold="availableBatch.alrUpperThreshold"
              :average-labour-units="availableAverageLabourUnits"
              :average-labour-values="availableAverageLabourValues"
              :average-non-labour-values="availableAverageNonLabourValues"
              :average-total-ex-gst="availableAverageTotalExGst"
              :adjusted-total="availableAdjustedTotal" />
          </div>
          <div class="is-divider" />
          <div class="field"
            style="max-width:45vw; overflow: auto">
            <bulma-table class="table is-bordered is-striped is-narrow is-fullwidth"
              :columns="availableQuoteColumns"
              :page-index="availableQuoteFilter.pageIndex"
              :page-size="availableQuoteFilter.pageSize"
              :total-rows="availableQuoteTotalRows"
              :is-loading="isAvailableQuoteTableLoading"
              :sort-column="availableQuoteFilter.sortColumn"
              :sort-order="availableQuoteFilter.sortOrder"
              :draggable="true"
              :draggable-empty="true"
              :show-pagination="false"
              @pageChanged="onAvailableQuotePageChange"
              @sort="onAvailableQuoteSort">
              <draggable :list="availableQuotes"
                group="quote"
                v-bind="{'disabled': !isAvailableQuoteRowDraggable, chosen: 'chosen', ghostClass: 'draggable-ghost', dragClass: 'draggable-drag', animation: animationDuration}"
                :tag="'tbody'"
                @change="onAvailableQuoteMoved"
                @start="onAvailableQuoteStartMove"
                @end="onAvailableQuoteEndMove">
                <tr v-for="(quote, index) in filteredAvailableQuotes"
                  :key="quote.quoteId"
                  @click="highlightSelectedAvailableQuote(index, $event)">
                  <td>{{ quote.quoteNo }}</td>
                  <!-- <td>{{ quote.claimNo }}</td> -->
                  <td>{{ quote.rego }}</td>
                  <!-- <td>{{ quote.customerName }}</td> -->
                  <!-- <td class="has-text-right">{{ quote.excess | formatNumber($userInfo.locale) }}</td> -->
                  <td class="has-text-right">{{ quote.labourHour | formatNumber($userInfo.locale) }}</td>
                  <td class="has-text-right">{{ quote.rate | formatNumber($userInfo.locale) }}</td>
                  <td class="has-text-right">{{ quote.labourTotal | formatNumber($userInfo.locale) }}</td>
                  <td class="has-text-right">{{ quote.partTotal | formatNumber($userInfo.locale) }}</td>
                  <td class="has-text-right">{{ quote.subletTotal | formatNumber($userInfo.locale) }}</td>
                  <td class="has-text-right">{{ quote.miscTotal | formatNumber($userInfo.locale) }}</td>
                  <td class="has-text-right">{{ quote.totalExGst | formatNumber($userInfo.locale) }}</td>
                  <!-- <td class="has-text-right">{{ quote.delta | formatNumber($userInfo.locale) }}</td> -->
                  <td class="has-vertical-middle has-text-centered is-content-width">
                    <button class="button is-success is-small is-inverted tooltip is-tooltip-topright"
                      @click="addToBatch(index, quote)"
                      data-tooltip="Add to batch">
                      <span class="icon is-medium">
                        <i class="mdi mdi-plus mdi-24px" />
                      </span>
                    </button>
                  </td>
                </tr>
              </draggable>
              <template slot="empty">
                <draggable :list="availableQuotes"
                  group="quote"
                  v-bind="{'disabled': !isAvailableQuoteRowDraggable, chosen: 'chosen', ghostClass: 'draggable-ghost', dragClass: 'draggable-drag', animation: animationDuration}"
                  :tag="'tbody'"
                  @change="itemMoved"
                  @end="endMove">
                  <tr>
                    <td :colspan="availableQuoteColumns.length">
                      <section class="section">
                        <div class="content has-text-grey has-text-centered">
                          <span icon="icon is-large">
                            <i class="mdi mdi-48px mdi-emoticon-sad" />
                          </span>
                          <p>Nothing</p>
                        </div>
                      </section>
                    </td>
                  </tr>
                </draggable>
              </template>
            </bulma-table>
          </div>
        </article>
      </div>
      <!-- </div> -->
    </transition>
  </div>
</template>

<script>
import { BatchQuoteColumns, AvailableQuoteColumns } from './columns'
import OrmBatchingService from './OrmBatchingService'
import BulmaTable from '@/components/BulmaTable'
import draggable from 'vuedraggable'
import VueNumeric from '@/components/VueNumeric'
import { roundAwayFromZero, roundUp } from '@/components/utils/AccountingFunctions'
import { DateTimeFiltersMixin, NumberFiltersMixin, TextFiltersMixin } from '@/components/mixins/filters'
import _orderBy from 'lodash/orderBy'
import { EventHubTypes, OrmQuoteStatusTypes, OrmBatchStatusType } from '@/enums'
import _cloneDeep from 'lodash/cloneDeep'
import OrmBatchingMixin from './OrmBatchingMixin'
import Guid from '@/components/Guid'
import OrmBatchStatistics from './components/OrmBatchStatistics'

export default {
  name: 'OrmBatchingDetail',
  components: {
    BulmaTable,
    draggable,
    VueNumeric,
    OrmBatchStatistics
  },
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin, TextFiltersMixin, OrmBatchingMixin],
  props: {
    value: null,
    isAddQuoteActive: false
  },
  data: () => {
    return {
      innerValue: null,
      entities: null,
      animationDuration: 200,
      // quotes: null,
      totalRows: 0,
      isTableLoading: false,
      isRowDraggable: true,
      filter: {
        sortColumn: BatchQuoteColumns[0].name,
        sortOrder: BatchQuoteColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 100
      },
      availableQuotes: null,
      availableQuoteQuery: '',
      availableQuoteTotalRows: 0,
      isAvailableQuoteTableLoading: false,
      isAvailableQuoteRowDraggable: true,
      availableQuoteDrag: false,
      availableQuoteFilter: {
        sortColumn: AvailableQuoteColumns[0].name,
        sortOrder: AvailableQuoteColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 100
      },
      // isAddQuote: false,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ],
      batchDate: new Date(),
      totalLowerLimit: 0,
      totalUpperLimit: 0,
      activeBatchIds: null,
      selectedAvailableBatchId: Guid.empty(),
      availableBatch: null
    }
  },
  computed: {
    guid() {
      return Guid
    },
    batchQuoteColumns() {
      let columns = _cloneDeep(BatchQuoteColumns)
      columns[12].isVisible = this.isInvoiceColumnVisible
      if (!this.isAddQuoteActive) {
        return columns
      } else {
        columns[1].isVisible = false // ClaimNo
        columns[3].isVisible = false // Customer Name
        columns[4].isVisible = false // Excess
        columns[13].isVisible = false // QuoteStatus
        columns[14].isVisible = false // QDEStatus
        return columns
      }
    },
    isInvoiceColumnVisible() {
      return this.innerValue.batchStatus === OrmBatchStatusType.Completed || this.innerValue.batchStatus === OrmBatchStatusType.Closed
    },
    isSubmitQuoteButtonActive() {
      let active = this.innerValue.batchStatus !== OrmBatchStatusType.Completed && this.innerValue.batchStatus !== OrmBatchStatusType.Closed
      return active && !this.snapshotDiff && !this.isAddQuoteActive
    },
    availableQuoteColumns() {
      return AvailableQuoteColumns
    },
    availableQuoteDragOptions() {
      return {
        animation: 200,
        group: 'quotes',
        disabled: false,
        ghostClass: 'ghost'
      }
    },
    alr() {
      if (this.entities.length === 0) {
        return 0
      }
      const batchTotalLabourHour = this.entities.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourHour)
      }, 0)
      const batchNonLabourTotal = this.entities.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.nonLabourTotal)
      }, 0)
      const batchAgreedTotal = this.entities.length * this.innerValue.agreedArc
      // console.log(this.entities.length, batchTotalLabourHour, batchNonLabourTotal, batchAgreedTotal)
      const batchTotalLabourValue = batchAgreedTotal - batchNonLabourTotal
      return batchTotalLabourHour ? roundUp(batchTotalLabourValue / batchTotalLabourHour, 1) : 0
    },
    availableAlr() {
      if (!this.availableQuotes || this.availableQuotes.length === 0) {
        return 0
      }
      const batchTotalLabourHour = this.availableQuotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourHour)
      }, 0)
      const batchNonLabourTotal = this.availableQuotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.nonLabourTotal)
      }, 0)
      const batchAgreedTotal = this.availableQuotes.length * this.availableBatch.agreedArc
      const batchTotalLabourValue = batchAgreedTotal - batchNonLabourTotal
      return batchTotalLabourHour ? roundUp(batchTotalLabourValue / batchTotalLabourHour, 1) : 0
    },
    adjustedTotal() {
      if (this.entities.length === 0) {
        return 0
      }
      const labourHourTotal = this.entities.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourHour)
      }, 0)
      const nonLabourTotal = this.entities.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.nonLabourTotal)
      }, 0)
      return roundAwayFromZero(nonLabourTotal + labourHourTotal * this.alr)
    },
    availableAdjustedTotal() {
      if (!this.availableQuotes || this.availableQuotes.length === 0) {
        return 0
      }
      const labourHourTotal = this.availableQuotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourHour)
      }, 0)
      const nonLabourTotal = this.availableQuotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.nonLabourTotal)
      }, 0)
      return roundAwayFromZero(nonLabourTotal + labourHourTotal * this.availableAlr)
    },
    averageLabourUnits() {
      if (this.entities.length === 0) {
        return 0
      }
      const total = this.entities.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourHour)
      }, 0)
      return roundAwayFromZero(total / this.entities.length)
    },
    availableAverageLabourUnits() {
      if (!this.availableQuotes || this.availableQuotes.length === 0) {
        return 0
      }
      const total = this.availableQuotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourHour)
      }, 0)
      return roundAwayFromZero(total / this.availableQuotes.length)
    },
    averageLabourValues() {
      if (this.entities.length === 0) {
        return 0
      }
      const total = this.entities.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourTotal)
      }, 0)
      return roundAwayFromZero(total / this.entities.length)
    },
    availableAverageLabourValues() {
      if (!this.availableQuotes || this.availableQuotes.length === 0) {
        return 0
      }
      const total = this.availableQuotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourTotal)
      }, 0)
      return roundAwayFromZero(total / this.availableQuotes.length)
    },
    averageNonLabourValues() {
      if (this.entities.length === 0) {
        return 0
      }
      const total = this.entities.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.nonLabourTotal)
      }, 0)
      return roundAwayFromZero(total / this.entities.length)
    },
    availableAverageNonLabourValues() {
      if (!this.availableQuotes || this.availableQuotes.length === 0) {
        return 0
      }
      const total = this.availableQuotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.nonLabourTotal)
      }, 0)
      return roundAwayFromZero(total / this.availableQuotes.length)
    },
    averageTotalExGst() {
      if (this.entities.length === 0) {
        return 0
      }
      const total = this.entities.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.totalExGst)
      }, 0)
      return roundAwayFromZero(total / this.entities.length)
    },
    availableAverageTotalExGst() {
      if (!this.availableQuotes || this.availableQuotes.length === 0) {
        return 0
      }
      const total = this.availableQuotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.totalExGst)
      }, 0)
      return roundAwayFromZero(total / this.availableQuotes.length)
    },
    labourUnitTotal() {
      const total = this.entities.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourUnit)
      }, 0)
      return total
    },
    availableLabourUnitTotal() {
      if (!this.availableQuotes) {
        return 0
      }
      const total = this.availableQuotes.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourUnit)
      }, 0)
      return total
    },
    labourValueTotal() {
      const total = this.entities.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.labourValue)
      }, 0)
      return total
    },
    batchTotal() {
      const total = this.entities.reduce(function(total, quote) {
        return roundAwayFromZero(total + quote.totalExGst)
      }, 0)
      return total
    },
    filteredAvailableQuotes() {
      if (!this.isAddQuoteActive || !this.availableQuotes) {
        return null
      }
      const query = this.availableQuoteQuery.toLowerCase()
      let results = this.availableQuotes
      if (this.availableQuoteQuery) {
        results = this.availableQuotes.filter(q => q.quoteNo.toLowerCase().includes(query) || q.rego.toLowerCase().includes(query))
      }
      if (this.totalLowerLimit) {
        results = results.filter(q => q.totalExGst >= this.totalLowerLimit)
      }
      if (this.totalUpperLimit) {
        results = results.filter(q => q.totalExGst <= this.totalUpperLimit)
      }
      return results
    },
    ormQuoteStatusTypes() {
      return OrmQuoteStatusTypes
    },
    ormBatchStatusType() {
      return OrmBatchStatusType
    }
  },
  watch: {
    isAddQuoteActive: function(newVal, oldVal) {
      if (newVal) {
        this.availableQuoteQuery = ''
        this.getActiveBatchIds()
        // console.log(this.selectedAvailableBatchId)
        this.getBatchQuoteAvailableSummaries()
      } else {
        this.availableQuoteQuery = ''
        this.availableQuotes = null
        this.availableQuoteTotalRows = 0
      }
    },
    batchDate: function(newVal, oldVal) {
      if (newVal) {
        this.innerValue.batchDate = newVal.toISOString().split('.')[0] + 'Z'
      } else {
        this.innerValue.batchDate = null
      }
    },
    selectedAvailableBatchId(newVal, oldVal) {
      this.getBatchQuoteAvailableSummaries()
      if (newVal !== Guid.empty()) {
        console.log(newVal)
        this.getAvailableBatch(newVal)
      } else {
        this.availableBatch = null
      }
    }
  },
  created() {
    this.innerValue = this.value
    this.batchDate = new Date(`${this.innerValue.batchDate}`)
    this.getBatchQuoteSummaries()
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      console.log('EventHubTypes.EntitySaved')
      this.innerValue = this.value
      this.getBatchQuoteSummaries()
    })
  },
  methods: {
    highlightSelected() {},
    onPageChange() {},
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.entities = _orderBy(this.entities, this.filter.sortColumn, this.filter.sortOrder)
    },
    itemMoved(event) {
      console.log(event)
      if (event.added) {
        const quote = event.added.element
        const existingIndex = this.innerValue.quoteIds
          .map(q => {
            return q.quoteId
          })
          .indexOf(quote.quoteId)
        // console.log(existingIndex, quote.quoteId)
        if (existingIndex === -1) {
          this.innerValue.quoteIds.splice(this.innerValue.quoteIds.length, 1, {
            isDeleted: false,
            isDirty: false,
            isNew: true,
            quoteId: quote.quoteId,
            sourceBatchId: this.availableBatch ? this.availableBatch.batchId : Guid.empty()
          })
          quote.isNew = true
          if (this.availableBatch) {
            quote.sourceBatchId = this.availableBatch.batchId
          } else {
            quote.sourceBatchId = Guid.empty()
          }
        } else {
          const quoteId = this.innerValue.quoteIds[existingIndex]
          quoteId.isDeleted = false
          quoteId.destinationBatchId = this.availableBatch ? this.availableBatch.batchId : Guid.empty()
          this.innerValue.quoteIds.splice(existingIndex, 1, quoteId)
        }
      } else if (event.removed) {
        const quote = event.removed.element
        const existingIndex = this.innerValue.quoteIds
          .map(q => {
            return q.quoteId
          })
          .indexOf(quote.quoteId)
        console.log(existingIndex, quote.quoteId)
        if (existingIndex === -1) {
        } else {
          const quoteId = this.innerValue.quoteIds[existingIndex]
          if (quoteId.isNew) {
            this.innerValue.quoteIds.splice(existingIndex, 1)
          } else {
            quoteId.isDeleted = true
          }
        }
      }
    },
    endMove() {
      // console.log('endMove')
      this.totalRows = this.entities.length
      this.availableQuoteTotalRows = this.availableQuotes.length
      this.availableQuotes = _orderBy(this.availableQuotes, this.availableQuoteFilter.sortColumn, this.availableQuoteFilter.sortOrder)
      this.entities = _orderBy(this.entities, this.filter.sortColumn, this.filter.sortOrder)
      // this.availableQuoteFilter.sortColumn = ''
      // this.availableQuoteFilter.sortOrder = ''
    },
    highlightSelectedAvailableQuote() {},
    onAvailableQuotePageChange() {},
    onAvailableQuoteSort(name, order) {
      this.availableQuoteFilter.sortColumn = name
      this.availableQuoteFilter.sortOrder = order
      this.availableQuotes = _orderBy(this.availableQuotes, this.availableQuoteFilter.sortColumn, this.availableQuoteFilter.sortOrder)
    },
    onAvailableQuoteMoved() {},
    onAvailableQuoteStartMove() {
      this.availableQuoteDrag = true
    },
    onAvailableQuoteEndMove(event) {
      // console.log('onAvailableQuoteEndMove', this.entities.length)
      this.totalRows = this.entities.length
      this.availableQuoteTotalRows = this.availableQuotes.length
      this.availableQuotes = _orderBy(this.availableQuotes, this.availableQuoteFilter.sortColumn, this.availableQuoteFilter.sortOrder)
      this.entities = _orderBy(this.entities, this.filter.sortColumn, this.filter.sortOrder)
      // this.filter.sortColumn = ''
      // this.filter.sortOrder = ''
      // this.entities = _orderBy(this.entities, this.filter.sortColumn, this.filter.sortOrder)
    },
    addToBatch(index, quote) {
      const existingIndex = this.innerValue.quoteIds
        .map(q => {
          return q.quoteId
        })
        .indexOf(quote.quoteId)
      // console.log(existingIndex)
      if (existingIndex === -1) {
        this.innerValue.quoteIds.splice(this.innerValue.quoteIds.length, 1, {
          isDeleted: false,
          isDirty: false,
          isNew: true,
          quoteId: quote.quoteId,
          sourceBatchId: this.availableBatch ? this.availableBatch.batchId : Guid.empty()
        })
        quote.isNew = true
        if (this.availableBatch) {
          quote.sourceBatchId = this.availableBatch.batchId
        } else {
          quote.sourceBatchId = Guid.empty()
        }
      } else {
        const quoteId = this.innerValue.quoteIds[existingIndex]
        quoteId.isDeleted = false
      }
      this.entities.splice(this.entities.length, 1, quote)
      this.availableQuotes.splice(index, 1)
      this.entities = _orderBy(this.entities, this.filter.sortColumn, this.filter.sortOrder)
      this.totalRows = this.entities.length
      this.availableQuoteTotalRows = this.availableQuotes.length
    },
    removeFromBatch(index, quote) {
      const existingIndex = this.innerValue.quoteIds
        .map(q => {
          return q.quoteId
        })
        .indexOf(quote.quoteId)
      if (existingIndex >= 0) {
        const quoteId = this.innerValue.quoteIds[existingIndex]
        if (quoteId.isNew) {
          this.innerValue.quoteIds.splice(existingIndex, 1)
        } else {
          quoteId.isDeleted = true
          quoteId.destinationBatchId = this.availableBatch ? this.availableBatch.batchId : Guid.empty()
        }
      } else {
      }
      this.availableQuotes.splice(this.availableQuotes.length, 1, quote)
      this.entities.splice(index, 1)
      this.availableQuotes = _orderBy(this.availableQuotes, this.availableQuoteFilter.sortColumn, this.availableQuoteFilter.sortOrder)
      this.totalRows = this.entities.length
      this.availableQuoteTotalRows = this.availableQuotes.length
    },
    async getBatchQuoteSummaries() {
      this.isTableLoading = true
      // const results = await OrmBatchingService.getBatchQuoteSummaries(this.innerValue.batchId)
      const results = await OrmBatchingService.getBatchQuoteSummariesByIds(
        this.innerValue.batchId,
        this.innerValue.quoteIds.map(q => {
          return q.quoteId
        })
      )
      this.entities = _orderBy(results, this.filter.sortColumn, this.filter.sortOrder)
      const vm = this
      this.innerValue.quoteIds.forEach(q => {
        if (q.isNew) {
          const entity = vm.entities.find(e => e.quoteId === q.quoteId)
          if (entity) {
            entity.isNew = q.isNew
          }
        }
      })
      this.totalRows = this.entities.length
      this.isTableLoading = false
    },
    async getActiveBatchIds() {
      this.activeBatchIds = await OrmBatchingService.getActiveBatchIds(this.innerValue.batchId)
    },
    closeAvailableQuotes() {
      // this.$emit('add-quote', false)
      this.$emit('update:isAddQuoteActive', false)
      // this.isAddQuote = false
      this.availableQuoteQuery = ''
      this.availableQuotes = null
      this.availableQuoteTotalRows = 0
    },
    async getBatchQuoteAvailableSummaries() {
      this.isAvailableQuoteTableLoading = true
      this.availableQuotes = null
      const results = await OrmBatchingService.getBatchQuoteAvailableSummaries(this.selectedAvailableBatchId)
      const newQuoteIds = this.innerValue.quoteIds.filter(q => q.isNew)
      newQuoteIds.forEach(q => {
        const index = results.findIndex(r => {
          return r.quoteId === q.quoteId
        })
        if (index !== -1) {
          results.splice(index, 1)
        }
      })
      this.availableQuotes = _orderBy(results, this.availableQuoteFilter.sortColumn, this.availableQuoteFilter.sortOrder)
      this.availableQuoteTotalRows = this.availableQuotes.length
      this.isAvailableQuoteTableLoading = false
    },
    delta(quote) {
      return quote.totalExGst - this.innerValue.agreedArc
    },
    viewQuote(quoteId) {
      this.$emit('view-quote', quoteId)
    },
    getPropertyNameByValue(object, value) {
      return Object.keys(object)[Object.values(object).findIndex(i => i === value)]
    },
    async getAvailableBatch(batchId) {
      this.availableBatch = await OrmBatchingService.getEntity(batchId)
    }
  }
}
</script>

<style lang="scss" scoped>
$animation-time: 0.5s;

.slideInRight {
  -webkit-animation: slideInRight $animation-time;
  /* Safari 4+ */
  -moz-animation: slideInRight $animation-time;
  /* Fx 5+ */
  -o-animation: slideInRight $animation-time;
  /* Opera 12+ */
  animation: slideInRight $animation-time;
  /* IE 10+, Fx 29+ */
}

.slideOutRight {
  -webkit-animation: slideOutRight $animation-time;
  /* Safari 4+ */
  -moz-animation: slideOutRight $animation-time;
  /* Fx 5+ */
  -o-animation: slideOutRight $animation-time;
  /* Opera 12+ */
  animation: slideOutRight $animation-time;
  /* IE 10+, Fx 29+ */
}
</style>