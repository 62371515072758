<template>
  <div class="field-body">
    <div class="field">
      <label class="label">Count</label>
      <div class="control">
        <span>{{ count }}</span>
      </div>
    </div>
    <div class="field">
      <label class="label">Avg Labour (Hrs)</label>
      <div class="control">
        <span>{{ averageLabourUnits | formatNumber($userInfo.locale) }}</span>
      </div>
    </div>
    <div class="field">
      <label class="label">
        <span>ALR</span>
        <span v-show="alr < alrLowerThreshold || alr > alrUpperThreshold"
          class="icon has-text-danger tooltip is-tooltip-danger"
          :data-tooltip="alr < alrLowerThreshold ? 'ALR is below threshold' : 'ALR is above threshold'">
          <i class="mdi mdi-alert" />
        </span>
      </label>
      <div class="control"
        :class="{ 'has-text-danger' : alr < alrLowerThreshold || alr > alrUpperThreshold }">
        <span>{{ alr | formatCurrency($userInfo.locale) }}</span>
      </div>
    </div>
    <div class="field">
      <label class="label">Avg Labour ($)</label>
      <div class="control">
        <span>{{ averageLabourValues | formatCurrency($userInfo.locale) }}</span>
      </div>
    </div>
    <div class="field">
      <label class="label">Avg Non-Labour ($)</label>
      <div class="control">
        <span>{{ averageNonLabourValues | formatCurrency($userInfo.locale) }}</span>
      </div>
    </div>
    <div class="field">
      <label class="label">Avg Total</label>
      <div class="control">
        <span>{{ averageTotalExGst | formatCurrency($userInfo.locale) }}</span>
      </div>
    </div>
    <div class="field">
      <label class="label">Adjusted Total</label>
      <div class="control">
        <span>{{ adjustedTotal | formatCurrency($userInfo.locale) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { NumberFiltersMixin } from '@/components/mixins/filters'

export default {
  name: 'OrmBatchStatistics',
  components: {},
  filters: {},
  mixins: [NumberFiltersMixin],
  props: {
    count: 0,
    alr: 0,
    alrLowerThreshold: 0,
    alrUpperThreshold: 0,
    averageLabourUnits: 0,
    averageLabourValues: 0,
    averageNonLabourValues: 0,
    averageTotalExGst: 0,
    adjustedTotal: 0
  },
  data: () => {
    return {}
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {}
}
</script>