import { mapGetters } from 'vuex'

const moduleName = 'ormbatching'

export default {
  computed: {
    ...mapGetters(moduleName, {
      snapshotDiff: 'snapshotDiff'
    })
  },
  filters: {
    formatOrmBatchStatus(value) {
      if (value.toLowerCase() === 'none') {
        return '-- Batch Status --'
      } else {
        return value
          .replace(/([a-z])([A-Z])/g, '$1 $2')
          .replace(/\b([A-Z]+)([A-Z])([a-z])/, '$1 $2$3')
          .replace(/^./, function(str) {
            return str.toUpperCase()
          })
      }
    }
  }
}