<template>
  <div>
    <app-detail-header :show-toggle="true"
      :show-new="false"
      :show-print="false"
      :show-delete="false"
      :show-save="true"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <orm-batching-view-side-menu v-if="entity"
          ref="sideMenu"
          :entity-name="`${entity.batchNo}`"
          :count="entity.quoteIds.filter(q => !q.isDeleted).length" />
      </div>
      <div class="column">
        <router-view v-if="entity"
          :key="$route.fullPath"
          :route-name="$route.name"
          v-model="entity"
          :is-add-quote-active.sync="isAddQuoteActive"
          :quotes="entities"
          :filter="filter"
          :removed-quotes="removedQuotes"
          @view-quote="viewQuote"
          @quote-added="addQuote"
          @quote-removed="removeQuote"
          @sort="sort" />
      </div>
    </div>
    <unsaved-modal :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
    <!-- <vue-fab v-show="entity"
      main-tooltip="Batch Actions"
      position="bottom-right"
      bg-color="#194D33"
      :z-index="30"
      :actions="fabActions"
      @submitQuotes="submitQuotes"
      @submitInvoices="submitInvoices"
      @toggleAddQuotes="toggleAddQuotes" /> -->
  </div>
</template>

<script>
import AppDetailHeader from '@/components/AppDetailHeader'
import OrmBatchingRoutes from './route-types'
import { AppHeaderButtonTypes, EventHubTypes, OrmBatchStatusType } from '@/enums'
import StoreMixin from './storeMixin'
import _cloneDeep from 'lodash/cloneDeep'
import { UnsavedModal } from '@/components/BulmaModal'
import OrmBatchingService from './OrmBatchingService'
import HttpStatus from '@/components/http-status'
import QuoteRoutes from '@/views/quote/route-types'
// import { VueFab } from '@/components/VueFab'
import OrmBatchingViewSideMenu from './OrmBatchingViewSideMenu'
import _orderBy from 'lodash/orderBy'
import { BatchQuoteColumns } from './columns'

export default {
  name: 'OrmBatchingView',
  components: {
    AppDetailHeader,
    UnsavedModal,
    // VueFab,
    OrmBatchingViewSideMenu
  },
  mixins: [StoreMixin],
  props: {},
  data: () => {
    return {
      entity: null,
      entities: null,
      isUnsavedModalActive: false,
      isSaveContinue: false,
      isSkipSave: false,
      isAddQuote: false,
      isAddQuoteActive: false,
      fabActions: [],
      filter: {
        sortColumn: BatchQuoteColumns[0].name,
        sortOrder: BatchQuoteColumns[0].defaultOrder,
        pageIndex: 1,
        pageSize: 100
      },
      availableQuotes: null,
      removedQuotes: []
    }
  },
  computed: {
    isSubmitQuoteButtonActive() {
      let active = this.entity && this.entity.batchStatus !== OrmBatchStatusType.Completed && this.entity.batchStatus !== OrmBatchStatusType.Closed
      return active && !this.snapshotDiff //&& !this.isAddQuoteActive
    },
    isSubmitInvoiceButtonActive() {
      let active = this.entity && this.entity.batchStatus === OrmBatchStatusType.Completed
      return active && !this.snapshotDiff
    }
  },
  watch: {
    entity: {
      handler: function(newVal) {
        if (newVal) {
          this.saveSnapshot(_cloneDeep(this.entity))
        }
      },
      deep: true
    },
    isSubmitQuoteButtonActive: function(newVal, oldVal) {
      this.setupFabButtons()
    },
    isSubmitInvoiceButtonActive(newVal, oldVal) {
      this.setupFabButtons()
    },
    isAddQuoteActive: function(newVal, oldVal) {
      this.setupFabButtons()
    }
  },
  created() {
    this.setupFabButtons()
    this.getEntity()
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          this.save()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },
    async getEntity(reset = false) {
      try {
        this.$showSpinner()
        if (reset) {
          this.clearSessionStorage()
          this.clearSnapshots(this.$route.params.batchId)
        }
        if (!this.currentSnapshot) {
          await this.getStoreItem(this.$route.params.batchId)
        }
        this.entity = _cloneDeep(this.currentSnapshot)
        this.getBatchQuoteSummaries()
      } catch (e) {
        this.$notification.openMessageXhrError('', e)
      }
      this.$hideSpinner()
    },
    clearSessionStorage() {},
    async save(isDelete = false) {
      const title = 'Orm Batching'
      try {
        this.$showSpinner('Saving...')
        let response
        this.saveSnapshot(_cloneDeep(this.entity))
        if (this.entity.isNew) {
          response = await OrmBatchingService.postEntity(this.entity)
        } else if (this.snapshotDiff) {
          response = await OrmBatchingService.putEntity(_cloneDeep(this.entity), this.snapshotDiff)
        } else {
          this.$notification.openNotificationWithType('warning', title, 'No changes. Not saved')
        }
        this.$hideSpinner()
        if (this.isSaveContinue) {
          this.$notification.openNotificationWithType('success', title, isDelete ? `${title} deleted` : 'Save successful')
        } else if (response && response.status === HttpStatus.NO_CONTENT) {
          console.log('saved')
          await this.getEntity(true)
          this.$notification.openNotificationWithType('success', title, isDelete ? `${title} deleted` : 'Save successful')
        } else if (response && response.status === HttpStatus.CREATED) {
          await this.getEntity(true)
          this.$notification.openNotificationWithType('success', title, `New ${title} added`)
        }
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
        return true
      } catch (e) {
        this.$hideSpinner()
        if (e.response && e.response.request && e.response.request.status === HttpStatus.CONFLICT) {
          this.isSaveConflictModalActive = true
        } else if (e.response) {
          this.$notification.openMessageXhrError('Orm Batching', e)
        } else {
          this.$notification.openNotificationWithType('danger', 'ORM Batching', e.toString())
        }
        return false
      }
    },
    cancel() {
      if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
        console.log(this.snapshotDiff)
        this.isUnsavedModalActive = true
      } else {
        if (this.toRoute) {
          this.$router.push(this.toRoute.fullPath)
        } else {
          this.$router.push({
            name: OrmBatchingRoutes.OrmBatchingListView.name
          })
        }
      }
    },
    skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true
      this.cancel()
    },
    async saveContinue() {
      this.isUnsavedModalActive = false
      this.isSaveContinue = true
      this.isSaveContinue = await this.save()
      if (this.isSaveContinue) {
        this.cancel()
      }
    },
    closeModal() {
      this.isUnsavedModalActive = false
      this.isSaveContinue = false
    },
    viewQuote(quoteId) {
      console.log(quoteId)
      this.setQuoteReturnRoute(this.$route)
      this.editStoreQuote(quoteId)
    },
    sort(name, order) {
      this.filter.sortColumn = name
      this.filter.sortOrder = order
      this.entities = _orderBy(this.entities, this.filter.sortColumn, this.filter.sortOrder)
    },
    addQuote(quote) {
      const removedQuoteIndex = this.removedQuotes.map(q => { return q.quoteId }).indexOf(quote.quoteId)
      if (removedQuoteIndex >= 0) {
        this.removedQuotes.splice(removedQuoteIndex, 1)
      }
      this.entities.splice(this.entities.length, 1, quote)
      this.entities = _orderBy(this.entities, this.filter.sortColumn, this.filter.sortOrder)
      // this.entities = _orderBy(this.entities, 'quoteNo', '')
    },
    removeQuote(index, quote) {
      if (!quote.isNew) {
        this.removedQuotes.splice(this.removedQuotes.length, 1, quote)
      }
      this.entities.splice(index, 1)
    },
    async setupFabButtons() {
      this.fabActions.splice(0, this.fabActions.length)
      if (this.isSubmitInvoiceButtonActive) {
        this.fabActions.splice(this.fabActions.length, 1, {
          name: 'submitInvoices',
          icon: 'currency-usd',
          color: '#DBA901',
          tooltip: 'Submit Invoices',
          visible: true
        })
      }
      if (this.isSubmitQuoteButtonActive) {
        this.fabActions.splice(this.fabActions.length, 1, {
          name: 'submitQuotes',
          icon: 'upload',
          color: '#298A08',
          tooltip: 'Submit Quotes',
          visible: true
        })
      }
      // if (this.entity && this.entity.batchStatus !== OrmBatchStatusType.Closed) {
      //   this.fabActions.splice(this.fabActions.length, 1, {
      //     name: 'toggleAddQuotes',
      //     icon: !this.isAddQuoteActive ? 'plus' : 'close',
      //     color: !this.isAddQuoteActive ? '#2c5ba5' : '#DF3A01',
      //     tooltip: !this.isAddQuoteActive ? 'Manage Quotes' : 'Close Quotes',
      //     visible: true
      //   })
      // }
    },
    async getBatchQuoteSummaries() {
      this.isTableLoading = true
      const results = await OrmBatchingService.getBatchQuoteSummariesByIds(
        this.entity.batchId,
        this.entity.quoteIds.map(q => {
          return q.quoteId
        })
      )
      this.entities = _orderBy(results, this.filter.sortColumn, this.filter.sortOrder)
      const vm = this
      this.entity.quoteIds.forEach(q => {
        if (q.isNew) {
          const entity = vm.entities.find(e => e.quoteId === q.quoteId)
          if (entity) {
            entity.isNew = q.isNew
          }
        }
      })
      this.totalRows = this.entities.length
      this.isTableLoading = false
    },
    submitQuotes() {
      // console.log('submitQuotes')
    },
    submitInvoices() {
      // console.log('submitInvoices')
    },
    toggleAddQuotes() {
      // console.log('toggleAddQuotes')
      this.isAddQuoteActive = !this.isAddQuoteActive
    }
  },
  async beforeRouteLeave(to, from, next) {
    const isViewingQuote = to.name === QuoteRoutes.QuoteDetail.name
    // console.log(isViewingQuote)
    if (isViewingQuote) {
      next()
    } else if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
      this.$router.replace(from.path)
      this.toRoute = to
      this.isUnsavedModalActive = true
    } else {
      this.setReturnRoute({})
      this.clearSessionStorage()
      this.clearSnapshots(from.params.batchId)
      next()
    }
  }
}
</script>